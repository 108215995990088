import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../../../components/Layout'
import texasPhoto from '../../../assets/images/texas.jpg'
import SEO from '../../../components/seo'
// var moment = require('moment')

class TexasIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Texas | J&J Automatic Gate Service & Design"
          description="Get installation and repair in Texas today! Contact our team today for high-quality gate repair and installation in the state of Texas."
          // keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <div id="blog-hero-section" className="blog-hero-section text-center">
          <h1>Gate Installation and Repair in Texas</h1>
        </div>
        <div className="container custom-container my-5">
          <img
            src={texasPhoto}
            className="float-right p-3"
            width="50%"
            alt="texas"
          />
          <p className="mt-3">
            <strong>
              When you need expert automated gate installation and repair in
              Texas, choose J & J Gates Service and Design{' '}
            </strong>
            . With over 10 years of experience, we know the best systems and
            installation techniques to provide you with quality gate services.
            Serving the entire Dallas-Fort Worth area, we look forward to
            helping you find the perfect gate for your home or business! Call us
            today at if you need gate repair or installation in Texas.
          </p>
          <h3>Areas We Serve</h3>
          <p>
            Our gate company has offices throughout the Dallas-Fort Worth area
            to best serve our clients. Though we are a family-owned company, we
            cater to a large area in North Texas, including the following
            communities:
          </p>
          <div className="country-list">
            <ul>
              <li>
                <Link to="/service-area/texas/collin-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Collin
                  County
                </Link>
              </li>
              <li>
                <Link to="/service-area/texas/dallas-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Dallas
                  County
                </Link>
              </li>
              <li>
                <Link to="/service-area/texas/denton-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Denton
                  County
                </Link>
              </li>
              <li>
                <Link to="/service-area/texas/ellis-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Ellis
                  County
                </Link>
              </li>
              <li>
                <Link to="/service-area/texas/hood-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Hood County
                </Link>
              </li>
              <li>
                <Link to="/service-area/texas/johnson-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Johnson
                  County
                </Link>
              </li>
              <li>
                <Link to="/service-area/texas/parker-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Parker
                  County
                </Link>
              </li>
              <li>
                <Link to="/service-area/texas/tarrant-county">
                  <i className="fa fa-chevron-right fa-sm mr-2"></i> Tarrant
                  County
                </Link>
              </li>
            </ul>
          </div>
          <h3 className="mt-2">Our Gate Services</h3>
          <p>
            At J & J Gates Service and Design, we are proud to provide
            comprehensive gate services. From gate installation and repair to
            access systems and garage door repairs, we do it all. Using top
            quality materials and reliable manufacturers, you can count on your
            gate to be reliable and secure. Whether your priority is a beautiful
            entryway or securing your property, we work hard to deliver the best
            results in the area!
          </p>
          <p>
            <strong>
              Contact our team today at for quality gate repair and installation
              in Texas
            </strong>
            . With prefabricated and custom gate design, our crew is ready to
            help you find the perfect solution! Browse our website or check out
            our <Link to="/gallery">gallery</Link> for examples of our work, and
            don’t hesitate to call if you have questions!
          </p>
        </div>
      </Layout>
    )
  }
}

export default TexasIndex

export const TexasIndexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
